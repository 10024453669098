
let key = 'dchealthSMFWd9a6' //g1PuR0tDkvo0N70cRvi1fg
let iv = 'DCH236u15Md8Ycg1' //qyU4xVulIrPmTH4zHEcnWQ==
const Crypto = require('sm-crypto')
// 数据加密
export const setSm3 = (res) => {
//    let str = sm3(res, {
//     key: key,
//     iv: iv,

//     })
let str = Crypto.sm3(res).toString()
    return (str)
}



import request from './request';

//钱包列表
export const walletList = (data) => request({
    url: '/wallet/list',
    method: 'post',
    data
});

//钱包详情
export const walletDetail = (data) => request({
    url: '/wallet/detail',
    method: 'post',
    data
});
//开通作品查看权限
export const payArticleRead = (data) => request({
    url: '/wallet/payArticleRead',
    method: 'post',
    data
});

//作品收益详情
export const articleIncome = (data) => request({
    url: '/chat/articleIncome',
    method: 'post',
    data
});
//作品收益流水
export const articleIncomeListPage = (data) => request({
    url: '/chat/articleIncomeListPage',
    method: 'post',
    data
});
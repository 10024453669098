<template>
  <div class="Details" ref="dom">
    <div class="headers">
      <div class="Imgs">
        <div class="image-gallery">
          <div class="main-image-container">
            <el-image
              :src="bigMap.imageList[activeIndex].imageUrl"
              style="width: 100%; height: 640px"
              fit="cover"
            ></el-image>
          </div>
          <div class="thumbnail-container">
            <div
              v-for="(image, index) in bigMap.imageList"
              :key="index"
              :class="activeIndex == index ? 'active' : 'items'"
              @click="updateActiveIndex(index)"
            >
              <img
                :src="image.imageUrl"
                alt="Thumbnail"
                style="width: 100%; margin-bottom: -4px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bodys">
      <div class="bodyLeft">
        <div class="bigText">
          <div class="titKuang">
            <div v-if="showAlert" class="alert-box">
              <span v-if="bigMap.isFavor == 1">- 1</span>
              <span v-else>+ 1</span>
            </div>
            <div v-if="showAlert1" class="alert-box1">
              <span v-if="bigMap.isCollect == 1">- 1</span>
              <span v-else>+ 1</span>
            </div>
            <el-button
              type="primary"
              size="small"
              style="
                width: 100px;
                color: #000;
                font-weight: 600;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              "
              @click="gotoCreate"
              icon="el-icon-edit"
              >续写</el-button
            >
            <div class="text">
              已有续写 <span>{{ bigMap.childArticleNum }}</span
              >，续写深度 <span>{{ bigMap.level }}</span>
            </div>
            <div :class="bigMap.isFavor == 1?'isItem':'item'" @click="likeThis(2)">
              <img
                v-if="bigMap.isFavor == 1"
                style="width: 20px; margin-right: 6px"
                src="@/pages/Home/Content/images/Islike.png"
              />
              <img
                v-else
                src="@/assets/images/dianzan.png"
                style="width: 20px; margin-right: 6px"
                alt="Image"
              />
              <span v-if="bigMap.isFavor == 1">已赞</span>
              <span v-else>点赞</span>
            </div>
            <span>{{ bigMap.favorNum }}</span>
            <div :class="bigMap.isCollect == 1?'isItem':'item'" @click="likeThis(1)">
              <img
                v-if="bigMap.isCollect == 1"
                style="width: 20px; height: 20px; margin-right: 6px"
                src="@/pages/Home/Content/images/Iscollect.png"
              />
              <img
                v-else
                style="width: 20px; height: 20px; margin-right: 6px"
                src="@/assets/images/shoucang.png"
              />
              <span v-if="bigMap.isCollect == 1">已藏</span>
              <span v-else>收藏</span>
            </div>
            <span>{{ bigMap.collectNum }}</span>
            <div class="item">
              <img
                src="@/assets/images/songli.png"
                style="width: 20px; height: 20px; margin-right: 6px"
                alt="Image"
              />
              <span>送礼</span>
            </div>
            <span>{{ bigMap.giftNum }}</span>
            <div class="item">
              <img
                src="@/assets/images/pinglun.png"
                style="width: 20px; height: 20px; margin-right: 6px"
                alt="Image"
              />
              <span>评论</span>
            </div>
            <span>{{ bigMap.commentNum }}</span>
          </div>
          <div class="name">
            <div class="tit">{{ bigMap.title }}</div>
            <div class="nums">
              <span style="color: #999">作品收益:</span>
              <div class="money">
                <div>{{ bigMap.incomeEthNum }}</div>
                <img
                  style="width: 26px; margin-left: 8px"
                  src="@/assets/images/ethBig.png"
                  alt=""
                />
              </div>
              <div class="money">
                <div>{{ bigMap.incomePlatformCurrencyNum }}</div>
                <img
                  style="width: 26px; margin-left: 8px"
                  src="@/components/Header/images/btc.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="userBox">
            <div class="user">
              <el-avatar
                shape="square"
                size="medium"
                :src="bigMap.authorHeadImage || avatar"
              />
              <span class="nickName">{{ bigMap.authorName }}</span>
              <img
                style="margin-left: 12px"
                src="@/assets/images/kaipianren.png"
                alt=""
              />
            </div>
            <div class="date">发布于：{{ bigMap.publishDate }}</div>
          </div>
          <div class="contentBox" v-if="accountId">
            <div class="content" v-if="bigMap.authType != 3">
              {{ bigMap.content }}
            </div>
            <div class="noContent" v-else>
              <div class="noText">{{ bigMap.content }}</div>
              <div class="langBut" @click="shopping">
                <img
                  style="margin-right: 12px"
                  src="@/assets/images/noAccess.png"
                  alt=""
                />
                <span>账号暂无浏览权限</span>
                <span class="kaitong">开通</span>
              </div>
            </div>
          </div>

          <div class="noContent" v-else>
            <div class="noText">{{ bigMap.content }}</div>
            <div class="langBut" @click="gologin">
              <img
                style="margin-right: 12px"
                src="@/assets/images/noUser.png"
                alt=""
              />
              <span>请登录账号后浏览</span>
            </div>
          </div>
        </div>

        <div class="navigation">
          <div class="top">
            <div class="pages">
              <span>
                第{{ bigMap.level }}层续写，共计
                {{ bigMap.childArticleNum }} 篇作品
              </span>
              <div class="selects" v-if="bigMap.childArticleNum">
                <el-select
                  v-model="selectType"
                  placeholder="请选择"
                  size="mini"
                  @change="changeType"
                >
                  <el-option label="按续写热度排序" value="4"> </el-option>
                  <el-option label="按发布时间排序" value="2"> </el-option>
                  <el-option label="按阅读次数排序" value="3"> </el-option>
                </el-select>
              </div>
            </div>

            <div class="pageNum" v-if="bigMap.childArticleNum">
              第{{ queryParams.pageNo }}页，{{ queryParams.pageNo * 4 }}/
              {{ bigMap.childArticleNum }}
            </div>
          </div>
          <div
            class="datas"
            v-if="bigMap.childArticleNum"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.5)"
            element-loading-text="拼命加载中"
          >
            <el-button
              icon="el-icon-caret-left "
              :class="[
                queryParams.pageNo <= 1 ? 'datasButtonNo' : 'datasButton',
                'butLeft',
              ]"
              type="text"
              :disabled="queryParams.pageNo <= 1"
              @click="fanye(-1)"
            ></el-button>
            <el-button
              icon="el-icon-caret-right "
              :class="[
                queryParams.pageNo >= bigMap.childArticleNum / 4
                  ? 'datasButtonNo'
                  : 'datasButton',
                'butRight',
              ]"
              type="text"
              :disabled="queryParams.pageNo >= bigMap.childArticleNum / 4"
              @click="fanye(1)"
            ></el-button>
            <div
              :class="
                continuationID && continuationID != item.articleId
                  ? 'opConstItem'
                  : 'constItem'
              "
              v-for="item in childArticleList"
              :key="item.articleId"
              @click="goThis(item.articleId)"
            >
              <div class="img">
                <el-image
                  style="width: 100%; height: 100%; object-fit: cover"
                  :src="item.imageUrl"
                  fit="cover"
                ></el-image>
                <div class="title">{{ item.title }}</div>
              </div>

              <div class="center">
                <div class="likeItem">
                  <img
                    style="width: 16px; margin-right: 6px"
                    src="../../pages/myWork/images/write.png"
                  />
                  <span> {{ item.renewNum }}</span>
                </div>
                <div class="likeItem">
                  <img
                    style="width: 16px; margin-right: 6px"
                    src="../../pages/myWork/images/like.png"
                  />
                  <span> {{ item.favorNum }}</span>
                </div>
              </div>

              <div class="bottom">
                <el-avatar
                  shape="square"
                  size="small"
                  :src="item.authorHeadImage || avatar"
                />
                <span class="nickName">{{ item.authorName }}</span>
              </div>
            </div>
          </div>
          <div class="nodatas" v-else>
            <img src="@/assets/images/noDetailsXuXie.png" alt="Image" />
            <span style="margin-top: 20px">精彩故事，等你来续写！</span>
          </div>
        </div>
      </div>
      <div class="bodyRight">
        <div class="rightTit">
          <img
            src="@/assets/images/fire.png"
            style="width: 24px; height: 24px; margin-right: 6px"
            alt="Image"
          />
          <span>热门续写</span>
        </div>
        <div class="rightContent">
          <div
            class="hootConstItem"
            v-for="item in bigMap.hotArticleList"
            :key="item.articleId"
          >
            <div class="img">
              <div class="cengshu">
                第{{ item.level }}层
              </div>
              <el-image
                style="width: 100%; height: 100%; object-fit: cover"
                :src="item.imageUrl"
                fit="cover"
              ></el-image>
              <div class="title">{{ item.title }}</div>
            </div>

            <div class="center">
              <div class="likeItem">
                <img
                  style="width: 16px; margin-right: 6px"
                  src="@/pages/myWork/images/write.png"
                />
                <span> {{ item.renewNum }}</span>
              </div>
              <div class="likeItem">
                <img
                  style="width: 16px; margin-right: 6px"
                  src="@/pages/myWork/images/like.png"
                />
                <span> {{ item.favorNum }}</span>
              </div>
            </div>

            <div class="bottom">
              <el-avatar
                shape="square"
                size="small"
                :src="item.authorHeadImage || avatar"
              />
              <span class="nickName">{{ item.authorName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Continuation v-if="XXkaiguan" :id="continuationID" />
    <el-dialog :visible.sync="dialog1" width="700px">
      <div class="contentImg">
        <img src="@/assets/images/yuedu.png" />
        <div class="hei">
          账号暂无阅读权限，您可以消耗ETH或DAIC兑换此作品的阅读权限
        </div>
        <div class="hui">
          提示：向账号的钱包地址转入DAIC并保持地址内至少存有500
          DAIC，即可免费阅读全站所有作品及续写内容。
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialog1 = false"> 关闭 </el-button>
        <el-button size="small" type="primary" @click="duihuan">
          我要兑换
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="请选择要兑换的权限类别："
      :visible.sync="dialog2"
      width="700px"
    >
      <div class="contentImg">
        <div class="item">
          <div class="left">
            <el-radio v-model="radio" label="1"></el-radio>
            <div class="text">
              <div class="hei">全篇作品</div>
              <div class="hui">可浏览开篇及所有续写内容</div>
            </div>
          </div>
          <div class="right">
            <div class="text" style="margin-right: 15px">
              <div class="hei">0.005 ETH</div>
              <div class="hui">ETH余额：{{ moneyObj.eth }}</div>
            </div>
            <img style="width: 30px" src="@/assets/images/ethBig.png" alt="" />
          </div>
        </div>
        <div class="item">
          <div class="left">
            <el-radio v-model="radio" label="2"></el-radio>
            <div class="text">
              <div class="hei">开篇及前10层续写</div>
              <div class="hui">
                10层后的续写可点击对应内容后在弹窗中另行兑换
              </div>
            </div>
          </div>
          <div class="right">
            <div class="text" style="margin-right: 10px">
              <div class="hei">5 DAIC</div>
              <div class="hui">DAIC余额：{{ moneyObj.jrz }}</div>
            </div>
            <img
              style="width: 30px"
              src="@/components/Header/images/btc.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialog1 = false"> 关闭 </el-button>
        <el-button size="small" type="primary" @click="isduihuan">
          兑换
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="您正在兑换作品阅读权限："
      :visible.sync="dialog3"
      width="500px"
    >
      <div class="blackcircle">
        <span v-if="radio == 1">兑换支出：0.005 ETH</span>
        <span v-if="radio == 2">兑换支出：5 DAIC</span>
        <img src="@/assets/images/eth.png" alt="" />
      </div>
      <div class="word">
        <span style="margin-right: 100px"
          >将通过手机：{{ xxxx(userInfo.mobile) }} 接收短信验证码</span
        >
        <el-button
          size="mini"
          round
          type="primary"
          v-if="code.show"
          @click="getCode"
        >
          <span style="color: #000">发送短信</span>
        </el-button>

        <el-button type="info" v-else size="mini" round disabled>
          <span>{{ code.count }}s后重发</span></el-button
        >
      </div>
      <div class="word">请输入接收到的验证码：</div>
      <div class="haveCode" v-if="!code.show">
        <i class="el-icon-message" />短信验证码已发送，若未收到请耐心等待
      </div>
      <el-input v-model="mobileCode" placeholder="请输入验证码"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          class="footerBut"
          @click="dialogSbmit"
          ><span style="color: #000; font-weight: 600">提交</span>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { detail, search } from "@/api/article";
import { walletList, payArticleRead } from "@/api/money";
import { update } from "@/api/article";
import { mapState } from "vuex";
import { modifyInfo, sendAccountVerifyCode } from "@/api";
import { setSm3 } from "@/utils/sm3Util";
import Continuation from "./Continuation";
export default {
  components: {
    Continuation,
  },
  data() {
    return {
      bigMap: {
        imageList: [
          {
            imageUrl: "", //图片地址
            isMain: "", //是否为主图 1是 0否
          },
        ],
        authType: "", // 是否有权限查看
        articleId: "", //作品ID
        publishDate: "", //发布时间
        articleType: "", //作品类型，0开篇，1续写
        level: "", //续写深度
        childArticleNum: "", //续写数
        favorNum: "", //点赞数
        collectNum: "", //收藏数
        giftNum: "", //送礼数
        commentNum: "", //评论数
        title: "", //标题
        incomeEthNum: "", //以太坊的收益
        incomePlatformCurrencyNum: "", //平台的收益
        authorName: "", //作者名称
        authorHeadImage: "", //作者头像
        authorAccountId: "", //作者ID
        authType: "", //权限 1=所有权限 2= 可查看 3= 不可点击
        content: "", //作品内容
        accountId: "", //当前登录ID
        contentType: "", //内容类型 1 图片 2文本
        childArticleList: [
          {
            articleId: "",
          },
        ], //续写作品列表
        hotArticleList: [
          {
            articleId: "",
          },
        ], //热门作品列表

        isCollect: "", //是否收藏 1=是 0=否

        isFavor: "", //是否点赞 1=是 0=否
      },
      childArticleList: [
        {
          articleId: "",
        },
      ], //续写作品列表
      queryParams: {
        //续写列表查询条件
        pageNo: 1,
        pageSize: 4,
      },
      // 选中的图片id
      activeIndex: 0,
      selectType: "4",
      // 下一层续写的id
      continuationID: "",
      XXkaiguan: false,
      showAlert: false, // 点赞动画
      showAlert1: false, // 收藏动画
      loading: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      radio: "1",
      code: {
        show: true,
        timer: null, //设置计时器,
        count: "",
      },
      DZtimer: null,
      mobileCode: "",
      moneyObj: {
        eth: "0",
        jrz: "0",
      },
      avatar: require("../../components/Header/images/userImg.png"),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    changeType(){
      this.getList()
    },
    xxxx(tel) {
      if (!tel) {
        return "";
      }
      return tel.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
    },
    //前往登录
    gologin() {
      this.$alert("请登录您的几人作账号", "提示", {
        confirmButtonText: "前往登录",
        callback: (action) => {
          this.$router.push("/login");
        },
      });
    },

    //
    //点赞和收藏
    likeThis(type) {
      if (!this.accountId) {
        this.gologin();
        return;
      }
      if (type == 2) {
        this.showAlert = true;
      } else {
        this.showAlert1 = true;
      }
      const param = {
        accountId: this.accountId,
        articleId: this.agoId,
        type,
      };

      setTimeout(() => {
        update(param).then(({ code, msg }) => {
          if (code == 1000) {
            this.getData();
            if (type == 2) {
              this.showAlert = false;
            } else {
              this.showAlert1 = false;
            }
          }
        });
      }, 500);
    },
    dialogSbmit() {
      if (!this.accountId) {
        this.gologin();
        return;
      }
      let money = "";
      if (this.radio == "1") {
        money = this.moneyObj.eth;
      } else {
        money = this.moneyObj.jrz;
      }
      let param = {
        accountId: this.accountId,
        verifyCode: this.mobileCode, //验证码
        sm3Sign: setSm3(money), //余额SM3 签名，尾数不补去0 示例20.00 直接为20， 20.10 为20.1
        type: this.radio,
        articleId: this.agoId,
      };
      payArticleRead(param).then(({ code, data }) => {
        if (code == 1000) {
          this.dialog3 = false;
          this.getData();
        }
      });
    },
    getCode() {
      let param = {
        bussinessType: 2,
        mobile: setSm4(this.userInfo.mobile),
      };
      sendAccountVerifyCode(param).then(({ code, data, msg }) => {
        if (code == 1000 && data) {
          let TIME_COUNT = 60;
          if (!this.code.timer) {
            this.code.count = TIME_COUNT;
            this.code.show = false;
            this.code.timer = setInterval(() => {
              if (this.code.count > 0 && this.code.count <= TIME_COUNT) {
                this.code.count--;
              } else {
                this.code.show = true;
                clearInterval(this.code.timer);
                this.code.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
    shopping() {
      this.dialog1 = true;
      walletList({ accountId: this.accountId }).then(({ code, msg, data }) => {
        if (code == 1000) {
          data.forEach((i) => {
            if (i.walletType == "3") {
              this.moneyObj.eth = i.walletBalance;
            } else if (i.walletType == "1") {
              this.moneyObj.jrz = i.walletBalance;
            }
          });
        }
      });
    },
    gotoCreate() {
      // 判断是否登录
      if (!this.accountId) {
        this.gologin();
        return;
      }

      this.$router.push({
        path: "/Create",
        // 存创作类型
        query: {
          type: 2,
        },
      });
      //// 判断是否有昵称
      // if (this.userInfo.nickName) {
      // } else {
      //   this.$prompt("请输入昵称", "你需要先设置一个昵称:", {
      //     confirmButtonText: "提交",
      //     inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
      //     inputErrorMessage: "昵称格式不符合要求，请修改后提交",
      //   }).then(({ value }) => {
      //     let param = {
      //       accountId: this.accountId,
      //       userName: value,
      //     };
      //     modifyInfo(param).then(({ success }) => {
      //       if (success) {
      //         this.$store.dispatch("getUserInfo");
      //       }
      //     });
      //   });
      // }
    },
    getData() {
      let param = {
        articleId: this.agoId,
        accountId: this.accountId || "",
      };
      detail(param).then(({ code, data }) => {
        if (code == 1000) {
          this.bigMap = data;
          this.childArticleList = data.childArticleList;
        }
      });
    },
    fanye(num) {
      this.queryParams.pageNo += num;
      this.getList();
    },
    // 续写列表
    getList() {
      this.loading = true;
      let param = {
        articleType: 1,
        type: this.selectType, //1=热度 2= 发布时间 3=按阅读数 4= 续写热度
        articleId: this.agoId,
        accountId: this.accountId || "",

        ...this.queryParams,
      };
      search(param).then(({ code, data }) => {
        if (code == 1000) {
          this.childArticleList = data;
          this.loading = false;
        }
      });
    },
    goThis(id) {
      if (!this.accountId) {
        this.gologin();
        return;
      }
      if (this.bigMap.authType == 3) {
        this.shopping();
        return;
      }
      this.XXkaiguan = false;

      setTimeout(() => {
        this.continuationID = id;
        this.XXkaiguan = true;
      }, 20);
    },
    updateActiveIndex(index) {
      this.activeIndex = index;
    },
    duihuan() {
      this.dialog1 = false;
      this.dialog2 = true;
    },
    isduihuan() {
      this.dialog2 = false;
      this.dialog3 = true;
    },
  },
  computed: {
    ...mapState({
      accountId: (state) => state.user.accountId,
      userInfo: (state) => state.user.userInfo,
      agoId: (state) => state.page.agoId,
    }),
  },
};
</script>

<style scoped lang="less">
.Details {
  min-height: 90vh;
  .headers {
    width: 100%;
    height: 680px;
    background-color: #16191e;
    .Imgs {
      width: 1200px;
      margin: 0 auto;
      height: 100%;

      .image-gallery {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 20px 0;
        .main-image-container {
          flex: 1;
          height: 100%;
          margin-right: 30px;
        }
        .thumbnail-container::-webkit-scrollbar {
          width: 6px;
        }
        .thumbnail-container::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba(249, 249, 249, 0.2);
        }
        .thumbnail-container::-webkit-scrollbar-track {
          border-radius: 0;
          background: rgba(249, 249, 249, 0.1);
        }
        .thumbnail-container {
          display: flex;
          flex-direction: column;
          // height: 100%;
          width: 252px;
          overflow: hidden;

          .items {
            box-sizing: border-box;
            padding: 0;
            width: 246px;
            opacity: 0.8;
            margin-bottom: 32px;
          }
          .active {
            box-sizing: border-box;
            padding: 0;
            width: 246px;
            margin-bottom: 32px;
            border: 2px solid #01e099;
            opacity: 1;
          }
        }
      }
    }
  }

  .bodys {
    display: flex;
    width: 1200px;
    margin: 30px auto 0;
    .bodyLeft {
      flex: 1;
      margin-right: 30px;

      .bigText {
        box-sizing: border-box;
        width: 100%;
        padding: 20px;
        background-color: #16191e;
        border: 1px solid #666;
        border-radius: 8px;
        .titKuang {
          position: relative;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          height: 60px;
          width: 101%;
          margin: 8px -10px 18px -10px;
          align-items: center;
          border-radius: 30px;
          padding: 0 20px;
          background: #000;
          color: #fffbfb;

          .alert-box {
            position: absolute;
            animation: fadeOut 2s forwards;
            left: 420px;
          }
          .alert-box1 {
            position: absolute;
            animation: fadeOut 2s forwards;
            left: 570px;
          }
          @keyframes fadeOut {
            0% {
              opacity: 1;
              top: 20px;
            }
            50% {
              opacity: 0.8;
              top: 0px;
            }
            100% {
              opacity: 0;
              top: -40px;
            }
          }

          .text {
            span {
              font-weight: 600;
              font-size: 14px;
            }
          }
          .item {
            width: 80px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #d7d7d7;
            color: #000;
            border-radius: 4px;
            cursor: pointer;
          }
          .isItem {
            width: 80px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #424242;
            color: #A1A1A1;
            border-radius: 4px;
            cursor: pointer;
          }
        }
        .name {
          display: flex;
          .tit {
            flex: 1;
            font-size: 24px;
          }
          .nums {
            width: 280px;
            justify-content: space-between;
            display: flex;
            align-items: center;
            .money {
              display: flex;
              align-items: center;
            }
          }
        }
        .userBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0 30px;
          .user {
            display: flex;
            align-items: center;
            padding-left: 10px;
            .nickName {
              margin-left: 16px;
            }
            color: #ccc;
          }
          .date {
            color: #828282;
          }
        }
        .contentBox {
          .content {
            font-size: 16px;
            line-height: 2em;
            padding-bottom: 20px;
          }
        }
        .noContent {
          .noText {
            height: 15em;
            font-size: 16px;
            line-height: 2em;
            padding-bottom: 20px;
            overflow: hidden;
            mask-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 1) 30%,
              rgba(0, 0, 0, 0.3) 40%,
              rgba(0, 0, 0, 0.2) 50%,
              rgba(0, 0, 0, 0.05) 60%,
              rgba(0, 0, 0, 0) 70%
            );
          }

          .langBut {
            position: relative;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(255, 255, 255, 0.098);
            border: 2px solid rgba(255, 255, 255, 0.251);
            border-radius: 6px;
            cursor: pointer;
            .kaitong {
              position: absolute;
              right: 50px;
              top: 10px;
              color: #01e39b;
              cursor: pointer;
            }
          }
        }
      }
      .navigation {
        box-sizing: border-box;
        width: 100%;
        // height: 250px;
        padding: 8px 20px 20px;
        margin: 20px 0 0;
        background: linear-gradient(90deg, #913366 0%, #a87340 99%);
        border-radius: 4px;
        .top {
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .pages {
            display: flex;
            align-items: center;
            align-items: center;
            span {
              font-size: 18px;
              margin-right: 28px;
            }
            .selects{
              /deep/.el-input__inner{
                background: rgba(256, 256, 256, 0.3);
                border: none;
                color:#16191e;
              }
            }
          }
          .pageNum {
            font-size: 12px;
          }
        }
        .nodatas {
          margin-top: 40px;
          width: 100%;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          color: #d9d9d9;
        }
        .datas {
          width: calc(100% + 20px);
          margin-left: -20px;
          display: flex;
          align-items: center;
          position: relative;
          .datasButton {
            color: #fff;
            font-size: 50px;
          }
          .datasButtonNo {
            opacity: 0.4;
            color: #fff;
            font-size: 50px;
          }
          .butLeft {
            position: absolute;
            top: 60px;
            z-index: 999;
            left: 0;
          }
          .butRight {
            position: absolute;
            top: 60px;
            z-index: 999;
            right: -20px;
          }
          .constItem {
            box-sizing: border-box;
            width: 24%;
            margin-left: 20px;
            background: #18191d;
            cursor: pointer;
            .img {
              width: 100%;
              height: 120px;
              position: relative;
              .title {
                font-size: 14px;
                line-height: 30px;
                position: absolute;
                bottom: 0;
                font-weight: 600;
                padding-left: 6px;
                background-color: rgba(0, 0, 0, 0.5);
              }
            }

            .center {
              display: flex;
              align-items: center;
              padding: 4px 0;
              font-size: 12px;
              color: #646464;
              .likeItem {
                display: flex;
                align-items: center;
                margin-left: 8px;
              }
            }
            .bottom {
              width: 100%;
              display: flex;
              align-items: center;
              color: #ccc;
              padding: 6px;

              .nickName {
                margin-left: 16px;
              }
            }
          }
          .opConstItem {
            box-sizing: border-box;
            width: 24%;
            margin-left: 20px;
            background: #18191d;
            cursor: pointer;
            opacity: 0.6;
            .img {
              width: 100%;
              height: 120px;
              position: relative;
              .title {
                font-size: 14px;
                line-height: 30px;
                position: absolute;
                bottom: 0;
                font-weight: 600;
                padding-left: 6px;
                background-color: rgba(0, 0, 0, 0.5);
              }
            }

            .center {
              display: flex;
              align-items: center;
              padding: 4px 0;
              font-size: 12px;
              color: #646464;
              .likeItem {
                display: flex;
                align-items: center;
                margin-left: 8px;
              }
            }
            .bottom {
              width: 100%;
              display: flex;
              align-items: center;
              color: #ccc;
              padding: 6px;

              .nickName {
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
    .bodyRight {
      width: 252px;
      .rightTit {
        width: 100%;
        height: 40px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        border-radius: 4px;
        background: linear-gradient(90deg, #913366 0%, #a87340 99%);
        font-weight: 600;
      }
      .rightContent {
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: column;

        .hootConstItem {
          box-sizing: border-box;
          width: 100%;
          margin-bottom: 20px;
          background: #18191d;
          cursor: pointer;
          .img {
            width: 100%;
            height: 120px;
            position: relative;
            .cengshu{
              width: 50px;
              height: 22px;
              text-align: center;
              line-height: 22px;
              position: absolute;
              z-index: 100;
              top: 8px;
              left: 8px;
              font-size: 12px;
              border: 1px solid #d7d7d7;
              border-radius: 4px;
              color:#fff;
            }
            .title {
              font-size: 14px;
              line-height: 30px;
              position: absolute;
              bottom: 0;
              font-weight: 600;
              padding-left: 6px;
              background-color: rgba(0, 0, 0, 0.5);
            }
          }

          .center {
            display: flex;
            align-items: center;
            padding: 4px 0;
            font-size: 12px;
            color: #646464;
            .likeItem {
              display: flex;
              align-items: center;
              margin-left: 8px;
            }
          }
          .bottom {
            width: 100%;
            display: flex;
            align-items: center;
            color: #ccc;
            padding: 6px;

            .nickName {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}
.contentImg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 0 50px;
  .hui {
    color: #828282;
    font-size: 12px;
  }
  .hei {
    color: #000;
    font-size: 16px;
    height: 30px;
  }
  .item {
    width: 90%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left,
    .right {
      display: flex;
      align-items: center;
    }
    /deep/.el-radio__label {
      display: none;
    }
  }

  .blackcircle {
    display: flex;
    justify-content: center;
    height: 38px;
    width: 200px;
    margin-bottom: 38px;
    align-items: center;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.6);
    color: #fffbfb;
    img {
      margin-left: 8px;
    }
  }
  .word {
    color: #333;
    height: 40px;
  }
  .haveCode {
    color: #828282;
    height: 40px;
    display: flex;
    align-items: center;
    i {
      font-size: 22px;
      margin-right: 6px;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    .footerBut {
      width: 200px;
    }
  }
}
</style>
